import React, {useState, useEffect, createRef} from 'react';
import './MoviesList.scss';
import {useSelector} from 'react-redux';
import {classControl} from '../../helper';


function Movie(props){
    const place = props.place, name = props.name, year = props.year, rating = props.rating;
    return (
        <tr className="movie" ref={props.innerRef}>
            <td className="movie__place">{place}</td>
            <td className="movie__name">{name}</td>
            <td className="movie__year">{year}</td>
            <td className="movie__rating">{rating}</td>
        </tr>
    );
}

function MoviesList(){
    

    const [movies, setMovies] = useState([]);
    const [showAllMovies, setShowAllMovies] = useState(false);
    const [movieButtonText, setMovieButtonText] = useState('Show All');

    const getUsers = (fullList=true) => {
        let l = (fullList) ? '' : '?l=20';
        fetch(`${process.env.REACT_APP_EXPRESS_URI}/movies${l}`, {
            credentials: 'include'
        })
            .then(res => res.json())
            .then(res => {
                setMovies(res.movies);
            })
            .catch(err => err);
    };

    useEffect(() => {
        getUsers(false);
    }, []);
    


    let movieClass = classControl('movies', useSelector((state) => state.menu.menuOpen));
    return (
        <div className={movieClass}>
            <div className="container">
                <p>Over the course of the pandemic, I took the liberty of writing each movie I can remember seeing to a MySQL database for use in a variety of applications. I'm leaving it here in case anyone needs a reccommendation or two to help pass the time. Movies are ordered by rating, then alphabetically.</p>
                <table className="movies-list">
                    <thead>
                        <tr>
                            <th>Place</th>
                            <th>Name</th>
                            <th>Year</th>
                            <th>Rating</th>
                        </tr>
                    </thead>
                    <tbody>
                        {movies.map((movie, ind) => {
                            const nodeRef = createRef(null);
                            return (
                                <Movie 
                                    key={ind}
                                    place={movie.place}
                                    name={movie.name} 
                                    year={movie.year}
                                    rating={movie.rating} 
                                    innerRef={nodeRef}
                                />
                            )}
                        )}
                    </tbody>
                </table>
                <button onClick={() => {
                    getUsers(!showAllMovies);
                    setShowAllMovies(!showAllMovies);
                    setMovieButtonText((!showAllMovies) ? 'Show Less' : 'Show All');
                }}>{movieButtonText}</button>
            </div>
        </div>
    );
    
}

export default MoviesList;