import React from 'react';
import './Header.scss';
import {useNavigate, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {openMenu, closeMenu, toggleMenu, setMenu} from '../state/menu';

function Navigation(props){

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();
    dispatch(setMenu(location.pathname === '/menu'));

    let menuOpen = useSelector((state) => state.menu.menuOpen);

    const dropMenu = () => {

        dispatch(toggleMenu());

        if(!menuOpen){
            dispatch(openMenu());
            navigate('/menu');
        }
        else{
            dispatch(closeMenu());
            navigate(-1);
        }
    }

    let menuClass = "navigation__slider";
    menuClass = menuOpen ? menuClass + " navigation__slider-open" : menuClass;

    let buttonClass = 'navigation__button';
    buttonClass = menuOpen ? buttonClass + ' navigation__button-open' : buttonClass;

    return (
        <div className="navigation">
            <div className={buttonClass} onClick={dropMenu}>
                <div className="navigation__button__line"></div>
                <div className="navigation__button__line hiding-line">
                    <div className="navigation__button__line__hidden"></div>
                </div>
                <div className="navigation__button__line"></div>
            </div>
            <div className={menuClass}></div>
        </div>
    );
    
}

export default Navigation;