import React, { useEffect, useState } from 'react';
import {useParams} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useSelector} from 'react-redux';
import {classControl} from '../../helper.js';
import skillSVGLoader from '../../SkillSVGLoader/SkillSVGLoader';

import './Project.scss';

function TechStackSkill(props){

    let skill;
    let data = props.data;

    if(data.icon) skill = <FontAwesomeIcon icon={['fab', data.icon]} />;
    else{
        skill = skillSVGLoader(data.image);
    }
    return (
        <div className='project__tech-stack__skill'>
            {skill}    
        </div>
    )
}

function TechStack(props){

    let skills = props.data;
    let cols = skills.length > 7 ? 7 : skills.length;
    return (
        
        <div className='project__tech-stack'>
            <h3>Tech Stack</h3>
            <div className='project__tech-stack__container' style={{gridTemplateColumns: `repeat( ${cols}, 1fr)`}}>
                {skills.map((skill, index) => 
                    <TechStackSkill key={index} data={skill} />
                )}
            </div>
        </div>
    )
}

function Project(){
    let params = useParams();
    const [project, setProject] = useState({});
    const [skills, setSkills] = useState([]);
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_EXPRESS_URI}/projects/${params.project}`, {
            credentials: 'include'
        })
            .then(res => res.json())
            .then(res => {
                setProject(res.project);
                setSkills(res.skills);
            })
            .catch(err => err);

            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
    }, []);

    let projectClass = classControl('project', useSelector((state) => state.menu.menuOpen));
    return (
        <div className={projectClass}>
            <div className="container">
                <div className="project__title">
                    <h1>{project.name}</h1>
                    {project.link &&
                    <   a href={project.link} target="_blank" rel="noreferrer"><FontAwesomeIcon icon='fa-solid fa-arrow-up-right-from-square' /></a>
                    }
                </div>
                <div className="project__image">
                    <img src={'/images/projects/' + project.image} alt={`${project.name}`} />
                </div>
                <p className="project__description">{project.description}</p>
                {skills &&
                    <TechStack data={skills} />
                }
            </div>
        </div>
    )
}

export default Project;