import {createSlice} from '@reduxjs/toolkit';

export const menuSlice = createSlice({
    name: 'menu',
    initialState: {
        menuOpen: false,
    },
    reducers: {
        openMenu: (state) => {
            state.menuOpen = true;
        },
        closeMenu: (state) => {
            state.menuOpen = false;
        },
        toggleMenu: (state) => {
            state.menuOpen = !state.menuOpen;
        },
        setMenu: (state, action) => {
            state.menuOpen = action.payload;
        }
    },
});

export const {openMenu, closeMenu, toggleMenu, setMenu} = menuSlice.actions;

export default menuSlice.reducer;