import React, {useState, useEffect} from 'react';
import Resume from './Resume';
import Portfolio from './Portfolio';
import Skills from './Skills';
import './Home.scss';
import {useSelector} from 'react-redux';
import {classControl} from '../../helper.js';

function Home(){

    const [portfolio, setPortfolio] = useState([]);
    const [skills, setSkills] = useState([]);

    const [activeSkill, setActiveSkill] = useState(-1);

    const [activeProjects, setActiveProjects] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_EXPRESS_URI}/projects`, {
            credentials: 'include'
        })
            .then(res => res.json())
            .then(res => {
                    setPortfolio(res);
                    setActiveProjects(new Array(res.length).fill('unset'));
            })
            .catch(err => err);
        
        fetch(`${process.env.REACT_APP_EXPRESS_URI}/skills`, {
            credentials: 'include'
        })
            .then(res => res.json())
            .then(res => {
                setSkills(res);
            })
            .catch(err => err);

    }, []);

    const fadeSkills = (ind) => {
        let index = (activeSkill === ind) ? -1 : ind;
        setActiveSkill(index);

        let newActiveProjects = new Array(activeProjects.length).fill('unset');

        if(index !== -1){
            for(let i = 0; i < portfolio.length; i++){
                newActiveProjects[i] = (skills[index].projects.includes(portfolio[i]._id) ? 'active' : 'inactive');
            }
        }
        setActiveProjects(newActiveProjects);
    };

    let homeClass = classControl('home', useSelector((state) => state.menu.menuOpen));
    return (
        <div className={homeClass}>
            <div className="container">
                <Resume />
                <Skills skills={skills} onClick={(i) => fadeSkills(i)} activeSkill={activeSkill} />
                <Portfolio portfolio={portfolio} activeProjects={activeProjects}/>
            </div>
        </div>
    )
}

export default Home;