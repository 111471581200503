import {useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {classControl} from '../../helper';
import {attemptLogin} from '../../Authentication/Authentication';

import "./Login.scss";

function Login(){

    const [credentials, setCredentials] = useState({
        userOrEmail: null,
        password: null,
    });

    const [checkLogin, setCheckLogin] = useState(true);
    const [loginError, setLoginError] = useState('');

    let loginClass = classControl('login', useSelector((state) => state.menu.menuOpen));
    const navigate = useNavigate();
    

    const handleFormSubmission = (e) => {
        e.preventDefault();
        const attempt = attemptLogin(credentials);
        attempt.then(res => {
            if(res.validLogin){
                navigate('/dashboard');
            }
            else{
                setLoginError(res.message);
            }
        });
    }

    useState(() => {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        }
        fetch(`${process.env.REACT_APP_EXPRESS_URI}/user`, options)
                .then(res => res.json())
                .then(res => {
                    setCheckLogin(res.loggedIn);
                    if(res.loggedIn){
                        navigate('/dashboard');
                    }
                })
                .catch(err => err);
    }, []);

    let user_field_class = `login__form__user${credentials.userOrEmail ? ' has-content' : ''}`;
    let pass_field_class = `login__form__pass${credentials.password ? ' has-content' : ''}`;

    return (
        <div className={loginClass}>
            {!checkLogin && 
                <div className="container">
                    <form className="login__form" onSubmit={(e) => handleFormSubmission(e)}>
                        <input className={user_field_class} type="text" placeholder="Username or Email" onChange={(e) => setCredentials({...credentials, userOrEmail: e.currentTarget.value})}/>
                        <input className={pass_field_class} type="password" placeholder="Password" onChange={(e) => setCredentials({...credentials, password: e.currentTarget.value})}/>
                        <input className="login__form__submit" type="submit" value="Submit" />
                    </form>
                    {loginError && 
                        <p>{loginError}</p>
                    }
                </div>
            }
        </div>
    );
}

export default Login;