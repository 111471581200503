import React, {useEffect} from 'react';
import './Header.scss';
import Navigation from './Navigation';
import {Link, useLocation} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useSelector, useDispatch} from 'react-redux';
import {closeMenu, setMenu} from '../state/menu';

function Header(){

    let menuOpen = useSelector((state) => state.menu.menuOpen);
    const dispatch = useDispatch();

    const location = useLocation();
    
    useEffect(() => {
        dispatch(setMenu(location.pathname === '/menu'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    return (
        <div className="header">
            <div className="container">
                <div className="headerLeft">
                    <Link to="/" onClick={() => dispatch(closeMenu())}><h1>Connor B. French</h1></Link>
                    <div className="header__links">
                        <a href="https://github.com/cbfrench" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', "github"]} className='header__links__github' /></a>
                        <a href="mailto:connorfrench716@gmail.com"><FontAwesomeIcon icon='fa-envelope' className='header__links__email' /></a>
                        <a href="/ConnorFrenchResume.pdf" target="_blank"><FontAwesomeIcon icon="fa-file" className='header__links__resume' /></a>
                    </div>
                </div>
                <Navigation menuOpen={menuOpen}/>
            </div>
        </div>
    );
}

export default Header;