import React, {useState, useEffect, useRef, createRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import skillSVGLoader from '../../SkillSVGLoader/SkillSVGLoader';


function Skill(props){
    let skill;
    if(props.icon) skill = <FontAwesomeIcon icon={['fab', props.icon]} />;
    else{
        skill = skillSVGLoader(props.image);
    }

    return (
        <div className="skill-transition-container" style={{transition: `all 0.3s ${(props.index + 1) * 0.1 + 0.3}s`}} ref={props.innerRef}>
            <div className={props.selected ? 'skill selected': 'skill'} onClick={() => props.onClick()}>
                {skill}
            </div>
        </div>
    );
}

function Skills(props){

    const [isSticky, setIsSticky] = useState(false);
    const ref = useRef();

    useEffect(()=>{

        const cachedRef = ref.current,
        observer = new IntersectionObserver(
                ([e]) => setIsSticky(e.intersectionRatio < 1),
                {
                  threshold: [1],
                  rootMargin: '-1px 0px 0px 0px',
                }
              );
    
        observer.observe(cachedRef);
        
        return function(){
          observer.unobserve(cachedRef)
        }
      }, []);

    return (
        <div className={isSticky ? "skills sticky" : "skills"} ref={ref}>
            <TransitionGroup className="skills-container">
                {props.skills.map((item, index) => {
                    const nodeRef = createRef(null);
                    return (
                        <CSSTransition
                            classNames='skill-transition'
                            key={index}
                            timeout={0}
                            nodeRef={nodeRef}
                        >
                            <Skill key={index}
                                name={item.name}
                                icon={item.icon}
                                projects={item.projects}
                                selected={props.activeSkill === index}
                                onClick={() => props.onClick(index)}
                                index={index}
                                image={item.image}
                                innerRef={nodeRef}
                            />
                        </CSSTransition>
                    )}
                )}     
            </TransitionGroup>
        </div>
    );
}

export default Skills;