import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import { classControl, formFocus } from '../../helper';
import {useNavigate} from 'react-router-dom';
import {logOut} from '../../Authentication/Authentication';
import './Dashboard.scss';

function MovieForm(){

    const [movieError, setMovieError] = useState('');
    const [movieStatus, setMovieStatus] = useState('');

    const [formFields, setFormFields] = useState({
        operation: 'add',
        name: '',
        rating: -1,
        year: -1,
        series: '',
    });

    const handleSubmission = (e) => {
        e.preventDefault();
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(formFields)
        }
        let attempt = fetch(`${process.env.REACT_APP_EXPRESS_URI}/movies`, options)
                .then(res => res.json())
                .then(res => {
                    let errorText = res.status ? '' : 'ERROR: There was a problem with one of your form fields, please ensure all required fields (name, year, rating) are filled in.';
                    let statusText = res.status ? res.message : '';
                    setMovieError(errorText);
                    setMovieStatus(statusText);
                })
                .catch(err => err);

        document.getElementById('dashboard__movie-form__form').reset();
    
        return attempt;
    }

    return (
        <div className='dashboard__movie-form'>
            <h3>Movies</h3>
            <form id="dashboard__movie-form__form" onSubmit={(e) => handleSubmission(e)}>
                <select id='dashboard__movie-form__select' className='dashboard__movie-form__field' onChange={(e) => setFormFields({...formFields, operation: e.currentTarget.value})} defaultValue='add'>
                    <option value="add">Add</option>
                    <option value="update">Update</option>
                    <option value="delete">Delete</option>
                </select>
                <input id='dashboard__movie-form__name' className={formFocus('dashboard__movie-form__field', formFields.name)} placeholder="Name" onChange={(e) => setFormFields({...formFields, name: e.currentTarget.value})} />
                {formFields.operation !== 'delete' && 
                    <input id='dashboard__movie-form__year' className={formFocus('dashboard__movie-form__field', formFields.year)} type="number" min="1900" max={new Date().getFullYear()} placeholder="Year" onChange={(e) => setFormFields({...formFields, year: parseInt(e.currentTarget.value)})} />
                }
                {formFields.operation !== 'delete' &&
                    <input id='dashboard__movie-form__rating' className={formFocus('dashboard__movie-form__field', formFields.rating, true)} type="number" min="0" max="10" step="0.1" placeholder="Rating" onChange={(e) => setFormFields({...formFields, rating: parseFloat(e.currentTarget.value).toFixed(1)})} />
                }
                {formFields.operation !== 'delete' &&
                    <input id='dashboard__movie-form__series' className={formFocus('dashboard__movie-form__field', formFields.series)} placeholder="Series" onChange={(e) => setFormFields({...formFields, series: e.currentTarget.value})} />
                }
                <input id='dashboard__movie-form__submit' className='dashboard__movie-form__field' type="submit" value={formFields.operation + ' Movie'} />
            </form>
            {movieError &&
                <p>{movieError}</p>
            }
            {movieStatus &&
                <p>{movieStatus}</p>
            }
        </div>
    );
}

function DashboardContent(props){

    const navigate = useNavigate();

    const logout = () => {
        const result = logOut();
        if(!result.loggedIn){
            navigate('/');
        }
    }

    return (
        <div className="dashboard__content">
            <div className="dashboard__content__header">
                <h1>Welcome, {props.user.username}</h1>
                <div className="dashboard__logout" onClick={logout}>Logout</div>
            </div>
            <p>Email: {props.user.email}</p>
            {props.user.user_level === 'ADMIN' && 
                <MovieForm />
            }
        </div>
    );
}

function Dashboard(){

    const navigate = useNavigate();
    const [loggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState({});

    const checkLoggedIn = () => {
        const options = {
            credentials: 'include',
        }
        fetch(`${process.env.REACT_APP_EXPRESS_URI}/dashboard`, options)
        .then(res => {
            return res.json();
        })
        .then(res => {
            if(!res.authorized){
                navigate('/login');
            }
            setLoggedIn(res.authorized);
            setUser(res.userInfo);
        })
        .catch(err => err);
    }

    useEffect(() => {
        checkLoggedIn();
    }, []);

    let dashboardClass = classControl('dashboard', useSelector((state) => state.menu.menuOpen));

    return (
        <div className={dashboardClass}>
            <div className="container">
                {loggedIn &&
                    <DashboardContent user={user}/>
                }
            </div>
        </div>
    )
}

export default Dashboard;