const classControl = (input, currentState) => {
    let fullClass = input + " content-container";
    fullClass = currentState ? fullClass + " menu-active": fullClass;
    fullClass = document.getElementById('menu') ? fullClass + " menu-closing" : fullClass;
    return fullClass;
}

const formFocus = (initialName, input, debug=false) => {
    if(debug){
        console.log(typeof input, input);
    }
    switch(typeof input){
        case 'string':
            return (input !== '' && input !== 'NaN') ? initialName + ' has-content' : initialName;
        case 'number':
            return (input >= 0) ? initialName + ' has-content' : initialName;
        default:
            return initialName;
    }
}

module.exports = {classControl, formFocus}