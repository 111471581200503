import React from 'react';
import {useSelector} from 'react-redux';
import {classControl} from '../helper.js';

function Err404(){
    let _404Class = classControl('404', useSelector((state) => state.menu.menuOpen));
    return (
        <div className={_404Class}>
            <div className="container">
                <h1>404</h1>
                <p>Could not find the page you're looking for, sorry!</p>
            </div>
        </div>
    )
}

export default Err404;