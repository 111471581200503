import React from 'react';
import './Footer.scss';

function Footer(){
    return (
        <div className="footer">
            <div className="container">
                <p>&copy;{(new Date().getFullYear())} Connor French</p>
            </div>
        </div>
    );
}

export default Footer;