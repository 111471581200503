import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store from './store';
import './index.css';
import App from './Routes/App/App';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {faEnvelope, faArrowUpRightFromSquare, faFile} from '@fortawesome/free-solid-svg-icons'

library.add(fab, faEnvelope, faArrowUpRightFromSquare, faFile);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
