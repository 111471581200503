import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {classControl} from '../../helper.js';
import './Info.scss';

function BodyParameters(props){
    return (
        <div className="route__body">
            <h4>Body Parameters</h4>
            {props.route.body.map((body, ind) => {
                return (
                    <p className="route__body-item" key={ind}><span className="route__body-item__parameter">{body.parameter}</span>: {body.description}</p>
                )
            })}
        </div>
    );
}

function RequiredParameters(props){
    return (
        <div className="route__required">
            <h4>Required Parameters</h4>
            {props.route.required.map((required, ind) => {
                return (
                    <p className="route__required-item" key={ind}><span className="route__required-item__parameter">{required.parameter}</span>: {required.description}</p>
                )
            })}
        </div>
    );
}

function RouteParameters(props){
    return (
        <div className="route__queries">
            <h4>Optional QueryString Parameters</h4>
            {props.route.query.map((parameter, ind) => {
                return (
                    <p className="route__queries-item" key={ind}><span className="route__queries-item__parameter">{parameter.parameter}</span>: {parameter.description}</p>
                )
            })}
        </div>
    );
}

function ContentRoute(props){
    let methodClass = `route__header__method ${props.route.method.toLowerCase()}`;
    return (
        <div className="route">
            <div className="route__header">
                <div className={methodClass}>{props.route.method}</div>
                <h3 className="route__header__route">{props.route.route}</h3>
            </div>
            {(props.route.description || props.route.required || props.route.body || props.route.query) && 
                <div className="route__content">
                    <p className="route__description">{props.route.description}</p>
                    {props.route.required && 
                        <RequiredParameters route={props.route} />
                    }
                    {props.route.body &&
                        <BodyParameters route={props.route} />
                    }
                    {props.route.query &&
                        <RouteParameters route={props.route}/>
                    }
                </div>
            }   
        </div>
    );
}

function ContentSection(props){
    return (
        <div className="content-section">
            <h2 className="content-section__heading">{props.content.section_heading}</h2>
            <div className="content-section__content" dangerouslySetInnerHTML={{__html: props.content.section_content}} />
            <div className="content-section__routes">
                {props.content.section_routes && props.content.section_routes.map((route, ind) => {
                    return(
                        <ContentRoute
                            key={ind}
                            route={route}
                        />
                    );
                })}
            </div>
        </div>
    );
}

function Info(){
    const [info, setInfo] = useState({});


    const getInfo = () => {
        fetch(`${process.env.REACT_APP_EXPRESS_URI}/info`, {
            credentials: 'include'
        })
            .then(res => res.json())
            .then(res => setInfo(res))
            .catch(err => err);
    }

    useEffect(() => {  
        getInfo();
    }, []);

    let infoClass = classControl('info', useSelector((state) => state.menu.menuOpen));
    let sections = info.sections && info.sections.sort((a, b) => {return a.section_order - b.section_order});
    return (
        <div className={infoClass}>
            <div className='container'>
                <h1>{info.content_heading}</h1>
                {sections && sections.map((section, ind) => {
                    return (
                        <ContentSection
                            key={ind}
                            content={section}
                        />
                    )
                })}
            </div>
        </div>
    );
}

export default Info;