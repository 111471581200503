const attemptLogin = (credentials) => {
    let validLogin = false;
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(credentials)
    }
    let attempt = fetch(`${process.env.REACT_APP_EXPRESS_URI}/login`, options)
            .then(res => {
                validLogin = (res.status === 200);
                return res.json();
            })
            .then(res => {
                res.validLogin = validLogin;
                return res;
            })
            .catch(err => err);

    return attempt;

}

const logOut = () => {
    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    }
    let result = fetch(`${process.env.REACT_APP_EXPRESS_URI}/logout`, options)
        .then(res => res.json())
        .then(res => {
            return res;
        });
    return result;
}

export {attemptLogin, logOut};