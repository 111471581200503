import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {classControl} from '../../helper';
import './VideoGames.scss';

function VideoGame(props){

    const [loaded, setLoaded] = useState(false);

    let imageClass = loaded ? 'loaded' : 'loading';
    let containerClass = 'video-games__list__game__image';
    containerClass = loaded ? containerClass + ' loaded' : containerClass;

    return(
        <div className="video-games__list__game" style={{transition: `all 0.3s ${props.ind * 0.3}s`}}>
            <div className={containerClass}>
                <img className={imageClass} src={'/images/games/' + props.image} onLoad={() => setLoaded(true)} alt={props.name}/>
            </div>
            <h5>{props.tagline}</h5>
        </div>
    );
}

function VideoGames(){
    
    const [games, setGames] = useState([]);

    const getGames = () => {
        fetch(`${process.env.REACT_APP_EXPRESS_URI}/games`, {
            credentials: 'include'
        })
            .then(res => res.json())
            .then(res => {
                setGames(res);
            })
            .catch(err => err);
    };

    useEffect(() => {
        getGames();
    }, []);
    


    let videoGameClass = classControl('video-games', useSelector((state) => state.menu.menuOpen));
    return (
        <div className={videoGameClass}>
            <div className="container">
                <div className="video-games__list">
                    {games.map((game, ind) => 
                        <VideoGame 
                            key={ind}
                            name={game.name}
                            tagline={game.tagline}
                            image={game.image}
                            ind={ind}
                        />
                    )}
                </div>
            </div>
        </div>
    );
    
}

export default VideoGames;