import React, {useState, createRef} from 'react';
import {Link} from "react-router-dom";
import {TransitionGroup, CSSTransition} from 'react-transition-group';

function PortfolioProject(props){

    const [hovering, setHovering] = useState(false);

    let name = props.name, image = props.image, slug = props.slug;
    image = 'images/projects/' + image;
    slug = '/projects/' + slug;
    let totalClass = 'portfolio-project';
    totalClass = (props.active !== 'unset') ? ((props.active === 'active') ? totalClass + ' active' : totalClass + ' inactive') : totalClass;
    totalClass = (hovering) ? totalClass + ' hovering' : totalClass;

    let lastOne = (props.arrLength % 3 !== 0 && props.index + 1 === props.arrLength);
    let lastTwo = (props.arrLength % 3 === 2 && props.index >= props.arrLength - 2);

    let containerClass = `portfolio-transition-container${lastOne ? ' __last-project' : ''}${lastTwo ? ' __last-projects' : ''}`;
    let hiddenTile = props.index % 3 !== 0;
    let tileTransition = hiddenTile ? `opacity 0.3s ${hiddenTile * hiddenTile * 0.3}s` : 'opacity 0.3s';

    return (
        <div className={containerClass} style={{transition: `${tileTransition}, transform ${((props.index % 3) + 1) * 0.3}s 0.3s`}} ref={props.innerRef}>
            <Link to={props.active ? slug : '/'}>
                <div className={totalClass} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
                    <div className="portfolio-project__tint"></div>
                    <div className="portfolio-project__background" style={{backgroundImage: `url(${image})`}}></div>
                    <div className="portfolio-project__content"><h2>{name}</h2></div>
                </div>
            </Link>
        </div>
    );
}

function Portfolio(props){

    let portfolio = props.portfolio;
    let activeProjects = props.activeProjects;

    return (
        <div className="portfolio">
            <TransitionGroup className="portfolio-container">
                {portfolio.map((item, index, {length}) => {
                    const nodeRef = createRef(null);
                    return (
                        <CSSTransition
                            classNames='portfolio-transition'
                            key={index}
                            timeout={1200}
                            nodeRef={nodeRef}
                        >
                            <PortfolioProject 
                                key={index}
                                id={item._id}
                                name={item.name}
                                image={item.image}
                                description={item.description}
                                link={item.link}
                                slug={item.slug}
                                active={activeProjects[index]}
                                index={index}
                                arrLength={length}
                                innerRef={nodeRef}
                            />
                        </CSSTransition>
                    )}
                )}
            </TransitionGroup>
        </div>
    );
}

export default Portfolio;