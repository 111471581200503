import {createSlice} from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        loggedIn: false,
        id: null,
    },
    reducers: {
        logIn: (state, action) => {
            if(action.type === 'user/logIn'){
                state.loggedIn = action.payload.loggedIn;
                state.id = action.payload.id;
            }
        }
    },
});

export const {logIn} = userSlice.actions;

export default userSlice.reducer;