import React, {useEffect, useState, lazy, Suspense} from 'react';
import {useSelector} from 'react-redux';
import {classControl} from '../../helper.js';

import './Milo.scss';

const MiloGallery = lazy(() => import('./MiloGallery'));

function Milo(){

    let miloClass = classControl('milo', useSelector((state) => state.menu.menuOpen));
    const [miloPath, setMiloPath] = useState('');
    const [miloImages, setMiloImages] = useState([]);
    const [loadNum, setLoadNum] = useState(20);
    const [showingMax, setShowingMax] = useState(false);

    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex !== 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
    }

    const returnToTop = () => {
        window.scrollTo(0, 0);
    }

    const loadMore = () => {
        if(showingMax) return;
        fetch(`${process.env.REACT_APP_EXPRESS_URI}/milo?l=20&s=${loadNum}`, {
            credentials: 'include'
        })
        .then(res => res.json())
        .then(res => {
            let images = shuffle(res.Contents);
            let combinedImages = [...miloImages, ...images];
            setMiloImages(combinedImages);
            if(combinedImages.length >= res.totalMedia){
                setShowingMax(true);
            }
        });
        setLoadNum(loadNum + 20);
    }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_EXPRESS_URI}/milo?l=20`, {
            credentials: 'include'
        })
        .then(res => res.json())
        .then(res => {
            let images = shuffle(res.Contents);
            setMiloPath(`${process.env.REACT_APP_S3_BUCKET_LINK}`);
            setMiloImages(images);
        })
        .catch(err => err);
    }, []);

    return (
        <div className={miloClass}>
            <div className="container">
                <p>To celebrate my best friend's birthday, I've decided to put all the pictures and videos I have of him in one place. Feel free to take a look at the little guy!</p>
                <Suspense fallback={<div>Loading...</div>}>
                    <MiloGallery miloImages={miloImages} miloPath={miloPath} loadMore={loadMore}/>
                </Suspense>
            </div>
            <div className="return-button" onClick={returnToTop}>Return to Top</div>
        </div>
    )
}

export default Milo;