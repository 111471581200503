import React, {useEffect, useState, createRef} from 'react';
import {Link} from 'react-router-dom';
import './Menu.scss';
import {useDispatch} from 'react-redux';
import {closeMenu} from '../../state/menu';

function MenuLink(props){

    const dispatch = useDispatch();
    return (
        <Link to={props.route} className="menu__links__link" onClick={() => dispatch(closeMenu())} ref={props.innerRef}>{props.text}</Link>
    );
}

function Menu(){

    const [loggedIn, setLoggedIn] = useState(false);

    const menuLinks = [
        {text:"Movies", route:"/movies"},
        //{text:"Video Games", route:"/games"},
        {text:"Info", route:"/info"},
        {text:"Milo", route:"/milo"},
        {text:`${loggedIn ? 'Dashboard' : 'Login'}`, route:`${loggedIn ? '/dashboard' : '/login'}`}
    ];

    const getUser = () => {
        const options = {
            credentials: 'include',
        }
        fetch(`${process.env.REACT_APP_EXPRESS_URI}/user`, options)
        .then(res => {
            return res.json();
        })
        .then(res => {
            setLoggedIn(res.loggedIn);
        })
        .catch(err => err);
    }

    useEffect(() => {
        getUser();
    }, []);


    return (
        <div className="menu content-container" id="menu">
            <div className="container">
                <div className="menu__links">
                    {menuLinks.map((link, ind) => {
                        const nodeRef = createRef(null);
                        return (
                            <MenuLink 
                                key={ind}
                                text={link.text} 
                                route={link.route} 
                                ind={ind}
                                innerRef={nodeRef}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Menu;