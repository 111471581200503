import React, {useState, useEffect, createRef} from 'react';
import {TransitionGroup, CSSTransition} from 'react-transition-group';

function ResumeItem(props){
    let resume_item = props.item, description = props.description, timeframe = props.timeframe;
    if(timeframe) description += ' \u00b7 ' + timeframe; 
    return (
        <div className="resume-item" style={{transition: `all 0.3s ${(props.index + 1) * 0.3}s`}} ref={props.innerRef}>
            <h3>{resume_item}</h3>
            <h4>{description}</h4>
        </div>
    );
}

function Resume(){
    const [resume, setResume] = useState([]);
    useEffect(() => {
        fetch(`${process.env.REACT_APP_EXPRESS_URI}/resume`)
            .then(res => res.json())
            .then(res => {
                res.sort((a, b) => a.order - b.order);
                setResume(res);
            })
            .catch(err => err);
    }, []);

    return (
        <div className="resume">
            <TransitionGroup className="resume-container">
                {resume.map((item, index) => {
                    const nodeRef = createRef(null);
                    return (
                        <CSSTransition
                            classNames='resume-transition'
                            key={index}
                            timeout={0}
                            nodeRef={nodeRef}
                        >
                            <ResumeItem key={index}
                                item={item.resume_item}
                                description={item.description}
                                timeframe={item.timeframe}
                                index={index}
                                innerRef={nodeRef}
                            />
                        </CSSTransition>
                    )}
                )}
            </TransitionGroup>
        </div>
    )
}

export default Resume;