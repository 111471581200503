import {ReactComponent as AmazonWebServicesSvg} from '../images/skills/aws.svg';
import {ReactComponent as CPlusPlusSvg} from '../images/skills/c-plus-plus.svg';
import {ReactComponent as CSharpSvg} from '../images/skills/c-sharp.svg';
import {ReactComponent as CloudwaysSvg} from '../images/skills/cloudways.svg';
import {ReactComponent as GatsbySvg} from '../images/skills/gatsby.svg';
import {ReactComponent as MySqlSvg} from '../images/skills/mysql.svg';

let skillSVGs = [
    {path: 'aws.svg', component: <AmazonWebServicesSvg />},
    {path: 'c-plus-plus.svg', component: <CPlusPlusSvg />},
    {path: 'c-sharp.svg', component: <CSharpSvg />},
    {path: 'cloudways.svg', component: <CloudwaysSvg />},
    {path: 'gatsby.svg', component: <GatsbySvg />},
    {path: 'mysql.svg', component: <MySqlSvg />}
];

const skillSVGLoader = (input) => {
    let s;
    for(s of skillSVGs){
        if(s.path === input){
            return s.component;
        }
    }
    return null;
}

export default skillSVGLoader;