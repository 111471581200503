import React from 'react';
import './App.scss';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

import Home from '../Home/Home';
import Menu from '../Menu/Menu';
import Info from '../Info/Info';
import MoviesList from '../Movies/MoviesList';
import Project from '../Project/Project';
import VideoGames from '../VideoGames/VideoGames';
import Milo from '../Milo/Milo';

import Login from '../Login/Login';
import Dashboard from '../Dashboard/Dashboard';
import Err404 from '../404';

import {BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom';
import {TransitionGroup, CSSTransition} from 'react-transition-group';


function Content(){
  const location = useLocation();

  return (
    <TransitionGroup className="content">
      <CSSTransition
        timeout={1000}
        classNames='fade'
        key={location.key}
      >
        <Routes location={location}>
          <Route path="/" element={<Home />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/movies" element={<MoviesList />} />
          <Route path="/info" element={<Info />} />
          <Route path="/projects">
            <Route path=":project" element={<Project />} />
          </Route>
          {/*<Route path="/games" element={<VideoGames />} />*/}
          <Route path="/milo" element={<Milo />} />

          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard /> } />
          <Route path="*" element={<Err404 />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

function App(){

  return (
    <div className="App">
      <Router>
        <Header />
        <Content />
        <Footer />
      </Router>
    </div>
  );
}


export default App;
